<template>
  <v-subheader :class="['sticky-subheader', { 'is-small': isSmall }]">
    <v-icon class="mr-4" color="primary">mdi-domain</v-icon>
    <div class="title dark--text text--darken-1">{{ organization.name }}</div>
    <div class="font-italic pl-2" v-if="organization.trial">({{ $tc('trial') }})</div>
  </v-subheader>
</template>

<script>

export default {
  props: {
    organization: {
      type: Object,
      default: () => {}
    },

    isSmall: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.sticky-subheader {
  position: sticky;
  position: -webkit-sticky;
  top: 64px;
  background: #FFFFFF;
  z-index: 3;
  height: 64px !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);

  &.is-small {
    top: 0px;
    justify-content: center;
    height: 56px !important;
  }
}
</style>
