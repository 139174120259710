<template>
  <v-container pt-0>
    <v-row justify="center" fill-height v-if="!hasOrders">
      <v-card class="mt-5" width="100%" outlined>
        <v-card-text class="text-center">{{ $tc('noStudies') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showShop">
            <v-icon left>mdi-text-box-plus-outline</v-icon>
            <span>{{ $tc('createStudy') }}</span>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-row>
    <v-sheet v-else>
      <v-list two-line subheader v-scroll="onScroll">
        <template v-for="i in virtualizedItems">
          <component :organization="i" :study="i" :key="i.id" :is="`${i.type}-list-item`" />
          <v-divider :key="`${i.id}-divider`" v-if="i.type === 'study'" />
        </template>
      </v-list>
      <v-btn color="primary shop-button" dark fixed depressed bottom right :x-large="!hasScrolled" :rounded="!hasScrolled" :fab="hasScrolled" @click="showFilter = true">
        <v-icon>mdi-filter-variant</v-icon>
        <v-slide-x-reverse-transition hide-on-leave>
          <span class="ml-2" v-if="!hasScrolled">{{ $tc('filter') }}</span>
        </v-slide-x-reverse-transition>
      </v-btn>
    </v-sheet>
    <filter-form :show.sync="showFilter" />
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { pickBy, isEmpty, intersection, words, concat } from 'lodash';
import FilterForm from '@/components/Filter';
import OrganizationListItem from '@/components/OrganizationListItem';
import StudyListItem from '@/components/StudyListItem';

export default {
  components: {
    FilterForm,
    OrganizationListItem,
    StudyListItem
  },

  data() {
    return {
      showFilter: false,
      lastItem: 20
    };
  },

  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    hasOrders() {
      return Object.keys(pickBy(this.orders, (value) => value.length)).length > 0;
    },

    numAllItems() {
      return Object.values(this.orders).reduce((count, studies) => count + studies.length, Object.keys(this.organizations).length);
    },

    filteredItems() {
      const items = [];
      this.organizations.slice(0).sort((a, b) => a.name.localeCompare(b.name)).filter((o) => isEmpty(this.filter.organizations) || this.filter.organizations.includes(o.id)).forEach((organization) => {
        organization.type = 'organization';
        items.push(organization);
        this.orders[organization.id].slice(0)
          .filter(({ status }) => this.filter.showArchived || (!this.filter.showArchived && status !== 'ARCHIVED'))
          .filter(({ product = '' }) => isEmpty(this.filter.products) || this.filter.products.includes(product))
          .filter(({ tags = [] }) => isEmpty(this.filter.tags) || intersection((tags).map((t) => t.toLowerCase()), this.filter.tags.map((t) => t.toLowerCase())).length > 0)
          .filter(({ name = '', description = '' }) => isEmpty(this.filter.terms) || !isEmpty(intersection(concat(words(name.toLowerCase()), words(description.toLowerCase())), this.filter.terms.map(t => t.toLowerCase()))))
          .sort((a, b) => {
            let [sortBy, desc] = this.filter.sortBy.split('-');
            desc = desc === 'desc';
            if (sortBy === 'created') {
              return (b.created - a.created) * (desc ? 1 : -1);
            }
            return a.name.localeCompare(b.name, undefined, { ignorePunctuation: true }) * (desc ? 1 : -1);
          })
          .forEach((study) => {
            study.type = 'study';
            items.push(study);
          });
      });
      return items;
    },

    virtualizedItems() {
      return this.filteredItems.slice(0, this.lastItem);
    },

    ...mapState(['organizations', 'orders', 'filter']),
    ...mapGetters(['hasScrolled'])
  },

  methods: {
    onScroll() {
      this.setOffsetTop(window.scrollY);

      if (this.lastItem > this.numAllItems) return;
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
      const showMoreItems = (scrollHeight - (scrollTop + clientHeight)) < 200;
      if (showMoreItems) {
        this.lastItem += 20;
      }
    },

    ...mapActions(['setOffsetTop', 'showShop'])
  }
};
</script>

<style lang="scss">
.shop-button {
  transition: all 0.2s;
  z-index: 4;
  height: 56px !important;
}
</style>
